import React, { useState, useEffect, useRef } from 'react';
import { getFirestore, doc, getDoc, updateDoc, collection, getDocs } from 'firebase/firestore';
import { useAuth } from '../context/AuthContext';
import Layout from '../components/common/Layout';
import SideMenu from '../components/common/SideMenu';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import { FaUser, FaUpload, FaTimes, FaCamera } from 'react-icons/fa';
import api from '../api';
import Loader from '../components/common/Loader';
import UpdatePasswordModal from './UpdatePasswordModal';

const ProfilePage = () => {
  const { user } = useAuth();
  const [profileData, setProfileData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    role: '',
    projects: [],
    manager_email: '',
  });
  const [isEditing, setIsEditing] = useState(false);
  const [projectOptions, setProjectOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [profileImageUrl, setProfileImageUrl] = useState(null);
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const fileInputRef = useRef(null);
  const db = getFirestore();

  useEffect(() => {
    if (user && user.email) {
      fetchUserProfile();
      fetchProjects();
    }
  }, [user]);

  const fetchUserProfile = async () => {
    setIsLoading(true);
    try {
      const response = await api.get(`api/users/${user.email}`);
      setProfileData(response.data);
      await fetchProfileImage(user.email);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching user profile:', error);
      toast.error(error.response?.data?.error || 'Failed to fetch user profile');
      setIsLoading(false);
    }
  };

  const fetchProjects = async () => {
    try {
      const db = getFirestore();
      const projectsCollection = collection(db, '/projects');
      const projectsSnapshot = await getDocs(projectsCollection);
      const projectsList = projectsSnapshot.docs.map(doc => ({
        value: doc.data().project_name,
        label: doc.data().project_name,
        ...doc.data()
      }));
      setProjectOptions(projectsList);
    } catch (error) {
      console.error('Error fetching projects:', error);
      toast.error('Failed to fetch projects');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData({ ...profileData, [name]: value });
  };

  const handleProjectsChange = (selectedOptions) => {
    const selectedProjects = selectedOptions.map(option => option.value);
    setProfileData({ ...profileData, projects: selectedProjects });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Validation for first name and last name
    if (!profileData.first_name.trim() || !profileData.last_name.trim()) {
      toast.error('First name and last name cannot be empty');
      setIsLoading(false);
      return;
    }
    setPreviewUrl(null)
    setSelectedFile(null);
    setProfileImageUrl(null)
    try {
      await api.put(`api/users/${user.email}`, profileData);
      setIsEditing(false);
      toast.success('Profile updated successfully');
      fetchUserProfile();
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error(error.response?.data?.error || 'Failed to update profile');
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setSelectedFile(null);
    setPreviewUrl(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    fetchUserProfile(); // Refresh the component with the current saved data
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    const allowedTypes = /jpeg|jpg|png/;
    const fileType = file.type.split('/')[1];

    if (!allowedTypes.test(fileType)) {
      toast.error('Only JPEG, JPG, and PNG files are allowed');
      return;
    }

    if (file && file.size <= 2 * 1024 * 1024) { // 2MB limit
      setSelectedFile(file);
      setPreviewUrl(URL.createObjectURL(file));
    } else {
      toast.error('Image size should be less than 2MB');
    }
  };

  const handleUpload = async () => {
    if (!selectedFile || !user.email) {
      toast.error('Please select an image and ensure you are logged in');
      return;
    }
    setIsUploading(true);
    const formData = new FormData();
    formData.append('profile_image', selectedFile);
    formData.append('email', user.email);

    try {
      const response = await api.post('api/users/upload-profile-image', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Image upload response:', response.data);
      toast.success('Image uploaded successfully');
      setProfileImageUrl(response.data.imageUrl);
      setPreviewUrl(null);
      setSelectedFile(null);
    } catch (error) {
      console.error('Error uploading image:', error);
      toast.error('Failed to upload image');
    } finally {
      setIsUploading(false);
    }
  };

  const handleRemoveImage = async () => {
    if (!user.email || !profileImageUrl) {
      toast.error('No image to remove');
      return;
    }

    try {
      await api.delete(`api/users/${user.email}/profile-image`);
      toast.success('Image removed successfully');
      setProfileImageUrl(null);
      setSelectedFile(null);
    } catch (error) {
      console.error('Error removing image:', error);
      toast.error('Failed to remove image');
    }
  };

  const handleCancelUpload = () => {
    setSelectedFile(null);
    setPreviewUrl(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const fetchProfileImage = async (email) => {
    try {
      const response = await api.get(`api/users/${email}/profile-image`);
      if (response.data.success) {
        setProfileImageUrl(response.data.imageUrl);
      } else {
        setProfileImageUrl(null); // Set to null if no image is found
      }
    } catch (error) {
      console.error('Error fetching profile image:', error);
      setProfileImageUrl(null); // Set to null if there's an error
    }
  };

  const canEdit = (field) => {
    if (!user || !user.role) return false;

    if (user.role === 'developer' || user.role === 'lead' || user.role === 'manager') {
      return ['first_name', 'last_name'].includes(field);
    }
    return false;
  };

  const togglePasswordModal = () => {
    setIsPasswordModalOpen(!isPasswordModalOpen);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const renderField = (key, value) => {
    const displayValue = (val) => {
      if (Array.isArray(val)) {
        return val.map(capitalizeFirstLetter).join(', ');
      } else if (typeof val === 'object' && val !== null) {
        return JSON.stringify(val);
      } else {
        return String(val);
      }
    };

    if (key === 'projects') {
      return (
        <div key={key} className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={key}>
            Projects
          </label>
          {isEditing && canEdit(key) ? (
            <Select
              isMulti
              name={key}
              options={projectOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              value={projectOptions.filter(option => value.includes(option.value))}
              onChange={handleProjectsChange}
            />
          ) : (
            <p className="py-2 px-3 bg-gray-100 rounded">{displayValue(value)}</p>
          )}
        </div>
      );
    }

    return (
      <div key={key} className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={key}>
          {key.charAt(0).toUpperCase() + key.slice(1).replace('_', ' ')}
        </label>
        {isEditing && canEdit(key) ? (
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id={key}
            type="text"
            name={key}
            value={String(value)}
            onChange={handleInputChange}
          />
        ) : (
          <p className="py-2 px-3 bg-gray-100 rounded">{displayValue(value)}</p>
        )}
      </div>
    );
  };

  // Define the order of fields
  const fieldOrder = ['first_name', 'last_name', 'email', 'role', 'projects', 'manager_email'];

  if (isLoading) {
    return (
      <Layout>
        <div className="flex justify-center items-center h-full">
          <Loader />
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <ToastContainer position="top-right" autoClose={3000} />
      <div className="flex w-full h-[calc(100vh-4rem)]">
        <SideMenu />
        <div className="flex-1 flex flex-col">
          <div className='h-14 flex items-center justify-between px-6 border-b border-gray-200 bg-gradient-to-r from-orange-500 to-orange-600'>
            <h1 className='font-semibold text-xl text-white'>User Profile</h1>
          </div>
          <div className='p-6 bg-gray-50 flex-1 overflow-y-auto'>
            <div className="bg-white p-6 rounded-lg shadow">
              <div className="flex flex-col md:flex-row">
                <div className="md:w-1/3 pr-4 mb-4 md:mb-0">
                  <div className="flex flex-col items-center justify-center">
                    <label htmlFor="user-image-upload" className="cursor-pointer mb-4 relative group">
                      <div className="w-48 h-48 rounded-full overflow-hidden shadow-lg transition-transform duration-300 bg-gradient-to-br from-orange-50 to-orange-100 p-1">
                        <div className="w-full h-full rounded-full overflow-hidden">
                          {previewUrl ? (
                            <img src={previewUrl} alt="Preview" className="w-full h-full object-cover" />
                          ) : profileImageUrl ? (
                            <img src={profileImageUrl} alt="User" className="w-full h-full object-cover" />
                          ) : (
                            <div className="w-full h-full bg-gradient-to-br from-gray-100 to-gray-300 flex items-center justify-center">
                              <FaUser className="text-gray-400 text-6xl" />
                            </div>
                          )}
                        </div>
                      </div>
                      {!selectedFile && !profileImageUrl && isEditing &&
                        <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-20 rounded-full transition-opacity duration-300 flex items-center justify-center">
                          <FaCamera className="text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300" size={24} />
                        </div>
                      }
                    </label>
                    {isEditing &&
                      <input
                        id="user-image-upload"
                        type="file"
                        accept="image/*"
                        className="hidden"
                        onChange={handleFileSelect}
                        ref={fileInputRef}
                      />
                    }
                    {!selectedFile && !profileImageUrl && isEditing &&
                      <><p className="text-sm text-gray-500 mb-2">Click to select an image</p><p className="text-xs text-gray-400 mb-4">Max size: 2MB</p></>
                    }
                    {selectedFile && isEditing && (
                      <div className="flex space-x-2">
                        <button
                          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center"
                          onClick={handleUpload}
                          disabled={isUploading}
                        >
                          <FaUpload className="mr-2" />
                          {isUploading ? 'Uploading...' : 'Upload'}
                        </button>
                        <button
                          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center"
                          onClick={handleCancelUpload}
                          disabled={isUploading}
                        >
                          <FaTimes className="mr-2" />
                          Cancel
                        </button>
                      </div>
                    )}
                    <div className="flex space-x-2">
                      {!selectedFile && profileImageUrl && isEditing && (
                        <button
                          onClick={handleRemoveImage}
                          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center"
                          title="Remove image"
                        >
                          Remove
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="md:w-2/3">
                  <form onSubmit={handleSubmit}>
                    {fieldOrder.map(key => {
                      if (key === 'manager_email' && profileData.role === 'manager') {
                        return null;
                      }
                      return renderField(key, profileData[key]);
                    })}
                    {isEditing ? (
                      <div className="flex justify-end">
                        <button
                          type="submit"
                          className="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          onClick={handleCancel}
                          className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        >
                          Cancel
                        </button>
                      </div>
                    ) : (
                      <div className="flex justify-between">
                        <button
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            setIsEditing(true);
                          }}
                          className="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        >
                          Edit Profile
                        </button>
                        <button
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            togglePasswordModal();
                          }}
                          className="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        >
                          Update Password
                        </button>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UpdatePasswordModal
        isOpen={isPasswordModalOpen}
        onClose={togglePasswordModal}
        userEmail={user.email}
      />
    </Layout>
  );
};

export default ProfilePage;
