import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { FaHome, FaTasks, FaPlus, FaUser, FaUsers, FaProjectDiagram } from 'react-icons/fa';
import { TbReportAnalytics } from "react-icons/tb";

const SideMenu = () => {
    const location = useLocation();
    const { user } = useAuth();

    const isActiveRoute = (path) => {
        return location.pathname === path;
    };

    const menuItems = {
        admin: [
            { path: '/dashboard', label: 'Dashboard', icon: FaHome },
            { path: '/create-user', label: 'Create User', icon: FaUser },
            { path: '/create-projects', label: 'Project', icon: FaProjectDiagram },
            { path: '/import-users', label: 'Import Users', icon: FaProjectDiagram },
        ],
        manager: [
            { path: '/dashboard', label: 'Dashboard', icon: FaHome },
            { path: '/task-list', label: 'Task List', icon: FaTasks },
            { path: '/team', label: 'Team', icon: FaUsers },
            { path: '/create-task', label: 'Create Task', icon: FaPlus },
            { path: '/create-projects', label: 'Project', icon: FaProjectDiagram },
            { path: '/profile', label: 'Profile', icon: FaUser },
            { path: '/report', label: 'Report', icon: TbReportAnalytics },
        ],
        developer: [
            { path: '/dashboard', label: 'Dashboard', icon: FaHome },
            { path: '/task-list', label: 'Task List', icon: FaTasks },
            { path: '/create-task', label: 'Create Task', icon: FaPlus },
            { path: '/profile', label: 'Profile', icon: FaUser },
        ],
        lead: [
            { path: '/dashboard', label: 'Dashboard', icon: FaHome },
            { path: '/profile', label: 'Profile', icon: FaUser },
            { path: '/task-list', label: 'Task List', icon: FaTasks },
            { path: '/create-task', label: 'Create Task', icon: FaPlus },
            { path: '/report', label: 'Report', icon: TbReportAnalytics },
        ],
    };

    const renderMenuItems = () => {
        const items = menuItems[user.role] || [];
        return items.map((item, index) => {
            const Icon = item.icon;
            return (
                <li key={index}>
                    <Link
                        to={item.path}
                        className={`flex items-center py-3 px-4 rounded-lg transition-colors duration-200 ${isActiveRoute(item.path)
                            ? 'bg-orange-500 text-white'
                            : 'text-gray-300 hover:bg-gray-800 hover:text-white'
                            }`}
                    >
                        <Icon className="h-6 w-6 mr-3" />
                        <span>{item.label}</span>
                    </Link>
                </li>
            );
        });
    };

    return (
        <div className='bg-gray-900 text-white w-64 min-h-[calc(100vh-4rem)]'>
            <nav className="h-full">
                <ul className='px-3 py-4 flex flex-col space-y-2'>
                    {renderMenuItems()}
                </ul>
            </nav>
        </div>
    );
}

export default SideMenu;