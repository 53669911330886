import React, { useState, useEffect } from 'react';
import { getFirestore, collection, query, where, getDocs, getDoc, doc } from 'firebase/firestore';
import { useAuth } from '../context/AuthContext';
import Layout from '../components/common/Layout';
import SideMenu from '../components/common/SideMenu';
import { toast, ToastContainer } from 'react-toastify';
import * as XLSX from 'xlsx';
import { logError } from '../services/errorLogService';

const Report = () => {
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [maxDate, setMaxDate] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();
  const db = getFirestore();

  useEffect(() => {
    // Set max date to today
    const today = new Date().toISOString().split('T')[0];
    setMaxDate(today);
  }, []);

  const handleFromDateChange = (e) => {
    const newFromDate = e.target.value;
    setFromDate(newFromDate);

    // Clear toDate if it's before the new fromDate
    if (toDate && newFromDate > toDate) {
      setToDate('');
      toast.warn('To date cleared as it was earlier than the new From date');
    }
  };

  const handleToDateChange = (e) => {
    const newToDate = e.target.value;
    setToDate(newToDate);

    if (fromDate && newToDate < fromDate) {
      toast.error('To date cannot be earlier than From date');
      setToDate('');
    } else if (newToDate > maxDate) {
      toast.error('To date cannot be in the future');
      setToDate(maxDate);
    }
  };

  const validateDates = () => {
    if (!fromDate || !toDate) {
      toast.error('Please select both From and To dates');
      return false;
    }

    if (fromDate > toDate) {
      toast.error('From date cannot be later than To date');
      return false;
    }

    if (toDate > maxDate) {
      toast.error('To date cannot be in the future');
      return false;
    }

    return true;
  };

  const generateReport = async () => {
    if (!validateDates()) {
      return;
    }

    setIsLoading(true);

    try {
      // Fetch user's projects
      const userDocRef = doc(db, 'user_details', user.email);
      const userDocSnap = await getDoc(userDocRef);

      if (!userDocSnap.exists()) {
        throw new Error('User document not found');
      }

      const userProjects = userDocSnap.data().projects || [];

      if (userProjects.length === 0) {
        toast.warn('No projects found for the user');
        setIsLoading(false);
        return;
      }

      if (userProjects.length === 0) {
        toast.warn('No projects found for the user');
        setIsLoading(false);
        return;
      }

      const tasksRef = collection(db, 'task_list');
      const fromDateTime = new Date(fromDate);
      const toDateTime = new Date(toDate);
      toDateTime.setHours(23, 59, 59, 999);

      const q = query(
        tasksRef,
        where('project_name', 'in', userProjects),
        where('created_at', '>=', fromDateTime),
        where('created_at', '<=', toDateTime)
      );

      const querySnapshot = await getDocs(q);
      const tasks = [];


      querySnapshot.forEach((doc) => {
        const taskData = doc.data();
        let assigned_by = taskData.assigned ? taskData.creator_email : '';
        const relevantDate = taskData.updated_at ? taskData.updated_at.toDate() : taskData.created_at.toDate();
        if (relevantDate >= fromDateTime && relevantDate <= toDateTime) {
          tasks.push({
            id: taskData.id,
            title: taskData.title,
            description: taskData.description,
            estimation: `${taskData.estimation} ${taskData.estimation_unit}`,
            status: taskData.status,
            project_name: taskData.project_name,
            assigned_by: assigned_by,
            assigned_to: taskData.assigned_to,
            created_at: taskData.created_at.toDate().toLocaleString(),
            updated_at: taskData.updated_at ? taskData.updated_at.toDate().toLocaleString() : 'N/A',
          });
        }
      });

      if (tasks.length === 0) {
        toast.info('No tasks found for the selected date range');
        setIsLoading(false);
        return;
      }

      const worksheet = XLSX.utils.json_to_sheet(tasks);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Tasks');

      const fileName = `Task_Report_${fromDate}_to_${toDate}.xlsx`;
      XLSX.writeFile(workbook, fileName);

      toast.success('Report generated and downloaded successfully');
    } catch (error) {
      await logError('firebase_generateReport', error.message, {
        collections: ['user_details', 'task_list'],
        dateRange: { from: fromDate, to: toDate },
        user: user.email,
        component: 'Report'
      });
      toast.error('Failed to generate report. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Layout>
      <ToastContainer position="top-right" autoClose={3000} />
      <div className="flex w-full h-[calc(100vh-4rem)]">
        <SideMenu />
        <div className="flex-1 overflow-y-auto">
          <div className='h-14 flex items-center justify-between px-6 border-b border-gray-200 bg-gradient-to-r from-orange-500 to-orange-600'>
            <h1 className='font-semibold text-xl text-white'>Generate Report</h1>
          </div>
          <div className='p-6 bg-gray-50'>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <div className="grid grid-cols-2 gap-4 mb-4">
                <div>
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="fromDate">
                    From Date
                  </label>
                  <input
                    type="date"
                    id="fromDate"
                    value={fromDate}
                    onChange={handleFromDateChange}
                    max={maxDate}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    required
                  />
                </div>
                <div>
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="toDate">
                    To Date
                  </label>
                  <input
                    type="date"
                    id="toDate"
                    value={toDate}
                    onChange={handleToDateChange}
                    min={fromDate}
                    max={maxDate}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    required
                  />
                </div>
              </div>
              <button
                onClick={generateReport}
                disabled={isLoading}
                className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                {isLoading ? 'Generating...' : 'Generate Report'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Report;
