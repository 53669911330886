import axios from 'axios';

const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/storage`;

export const uploadTaskFiles = async (files, taskId) => {
  console.log("files here", files[0].file);

  const formData = new FormData();
  files.forEach((file) => formData.append('files', file.file));
  formData.append('taskId', taskId);

  // Log FormData contents
for (let [key, value] of formData.entries()) {
  if (value instanceof File) {
    console.log(`FormData field ${key}: File name = ${value.name}`);
  } else {
    console.log(`FormData field ${key}:`, value);
  }
}

  try {
    const response = await axios.post(`${API_URL}/upload`, formData);
    return response.data;
  } catch (error) {
    console.error('Error uploading files:', error);
    throw error;
  }
};


export const getTaskFiles = async (taskId) => {
  const response = await axios.get(`${API_URL}/files/${taskId}`);
  return response.data;
};

export const deleteTaskFile = async (taskId, fileName) => {
  const response = await axios.delete(`${API_URL}/files/${taskId}/${fileName}`);
  return response.data;
};

export const deleteFile = async (fileUrl,taskId) => {
  try {
    const response = await axios.delete(`${API_URL}/comment-files`, { data: { fileUrl,taskId } });
    return response.data;
  } catch (error) {
    console.error('Error deleting file:', error);
    throw error;
  }
};