import React, { useState, useEffect, useMemo } from 'react';
import { useAuth } from '../context/AuthContext';
import { getFirestore, collection, query, orderBy, getDocs, doc, updateDoc, writeBatch } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import Layout from '../components/common/Layout';
import SideMenu from '../components/common/SideMenu';
import '../styles/notifications.css';
import { IoMailOpenSharp, IoMailUnreadSharp, IoNotificationsOffOutline } from "react-icons/io5";
import { logError } from '../services/errorLogService';

const NotificationsPage = () => {
    const { user } = useAuth();
    const [notifications, setNotifications] = useState([]);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [dateError, setDateError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        fetchNotifications();
    }, [user]);

    const fetchNotifications = async () => {
        if (!user) return;
        try {
            const db = getFirestore();
            const notificationsRef = collection(db, `user_details/${user.email}/notifications`);
            const q = query(notificationsRef, orderBy('created_at', 'desc'));
            const querySnapshot = await getDocs(q);
            const notificationsData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setNotifications(notificationsData);
        } catch (error) {
            await logError('firebase_fetchNotifications', error.message, {
                collection: `user_details/${user.email}/notifications`,
                user: user.email,
                component: 'NotificationsPage'
            });
        }
    };

    const updateNotificationStatus = async (notificationId, newStatus, event) => {
        event.stopPropagation();
        try {
            const db = getFirestore();
            const notificationRef = doc(db, `user_details/${user.email}/notifications`, notificationId);
            await updateDoc(notificationRef, { status: newStatus });
            fetchNotifications();
        } catch (error) {
            await logError('firebase_updateNotificationStatus', error.message, {
                collection: `user_details/${user.email}/notifications`,
                notificationId,
                newStatus,
                user: user.email,
                component: 'NotificationsPage'
            });
        }
    };

    const markAsRead = (notificationId, event) => updateNotificationStatus(notificationId, 'read', event);
    const markAsUnread = (notificationId, event) => updateNotificationStatus(notificationId, 'new', event);

    const markAllAsRead = async () => {
        try {
            const db = getFirestore();
            const batch = writeBatch(db);
            notifications.forEach(notification => {
                if (notification.status === 'new') {
                    const notificationRef = doc(db, `user_details/${user.email}/notifications`, notification.id);
                    batch.update(notificationRef, { status: 'read' });
                }
            });
            await batch.commit();
            fetchNotifications();
        } catch (error) {
            await logError('firebase_markAllNotificationsRead', error.message, {
                collection: `user_details/${user.email}/notifications`,
                notificationsCount: notifications.length,
                user: user.email,
                component: 'NotificationsPage'
            });
        }
    };

    const validateDates = () => {
        const today = new Date();
        today.setHours(23, 59, 59, 59);

        if (fromDate) {
            const fromDateObj = new Date(fromDate);
            if (fromDateObj > today) {
                setDateError('From date cannot be in the future');
                return false;
            }
        }

        if (fromDate && toDate) {
            const fromDateObj = new Date(fromDate);
            const toDateObj = new Date(toDate);
            if (fromDateObj > toDateObj) {
                setDateError('To date must be after From date');
                return false;
            }
        }

        setDateError('');
        return true;
    };

    const filteredNotifications = useMemo(() => {
        if (!validateDates()) return notifications;

        return notifications.filter(notification => {
            const notificationDate = new Date(notification.created_at.seconds * 1000);
            notificationDate.setHours(0, 0, 0, 0);

            const fromDateObj = fromDate ? new Date(fromDate) : null;
            const toDateObj = toDate ? new Date(toDate) : null;

            if (fromDateObj && toDateObj) {
                return notificationDate >= fromDateObj && notificationDate <= toDateObj;
            } else if (fromDateObj) {
                return notificationDate >= fromDateObj;
            } else if (toDateObj) {
                return notificationDate <= toDateObj;
            }
            return true;
        });
    }, [notifications, fromDate, toDate]);

    const clearFilters = () => {
        setFromDate('');
        setToDate('');
        setDateError('');
    };

    const isFilterActive = fromDate !== '' || toDate !== '';

    const sortNotifications = (notifications) => {
        return [...notifications].sort((a, b) => {
            if (a.status === 'new' && b.status !== 'new') return -1;
            if (a.status !== 'new' && b.status === 'new') return 1;
            return new Date(b.created_at.seconds * 1000) - new Date(a.created_at.seconds * 1000);
        });
    };

    const handleNotificationClick = (notification) => {
        if (notification.ref_id) {
            navigate(`/task/${notification.ref_id}`, { state: { from: 'notifications' } });
        }
    };

    const renderNotifications = (notifications) => {
        const sortedNotifications = sortNotifications(notifications);
        return (
            <div className="notification-list-inside">
                {sortedNotifications.map(notification => (
                    <div
                        key={notification.id}
                        className={`notification-item ${notification.status} ${notification.type === 'new-task' ? 'new-task' : ''}`}
                        onClick={() => handleNotificationClick(notification)}
                    >
                        <div className="notification-content">
                            <p>{notification.message}</p>
                            <span className="notification-date">
                                {formatDate(notification.created_at)}
                            </span>
                        </div>
                        {notification.status === 'new' ? (
                            <button
                                onClick={(e) => markAsRead(notification.id, e)}
                                className="mark-read-button"
                                title="Mark as Read"
                            >
                                <IoMailOpenSharp />
                            </button>
                        ) : (
                            <button
                                onClick={(e) => markAsUnread(notification.id, e)}
                                className="mark-unread-button"
                                title="Mark as Unread"
                            >
                                <IoMailUnreadSharp />
                            </button>
                        )}
                    </div>
                ))}
            </div>
        );
    };

    const formatDate = (dateInput) => {
        let date;
        if (dateInput instanceof Date) {
            date = dateInput;
        } else if (typeof dateInput === 'object' && dateInput.seconds) {
            // Handle Firestore Timestamp object
            date = new Date(dateInput.seconds * 1000);
        } else if (typeof dateInput === 'string' || typeof dateInput === 'number') {
            date = new Date(dateInput);
        } else {
            console.error('Invalid date format:', dateInput);
            return 'Invalid Date';
        }

        if (isNaN(date.getTime())) {
            console.error('Invalid date:', dateInput);
            return 'Invalid Date';
        }

        const options = { day: 'numeric', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit' };
        return date.toLocaleDateString('en-US', options);
    };

    return (
        <Layout>
            <div className="flex w-full h-[calc(100vh-4rem)]">
                <SideMenu />
                <div className="flex-1 overflow-y-auto">
                    <div className='h-14 flex items-center justify-between px-6 border-b border-gray-200 bg-gradient-to-r from-orange-500 to-orange-600'>
                        <h1 className='font-semibold text-xl text-white'>Notifications</h1>
                    </div>
                    <div className='p-6 bg-gray-50'>
                        <div className="flex justify-between items-center mb-4">
                            <div className="date-filter flex gap-4">
                                <input
                                    type="date"
                                    value={fromDate}
                                    onChange={(e) => setFromDate(e.target.value)}
                                    max={new Date().toISOString().split('T')[0]}
                                    className="border rounded p-2"
                                />
                                <input
                                    type="date"
                                    value={toDate}
                                    onChange={(e) => setToDate(e.target.value)}
                                    min={fromDate}
                                    className="border rounded p-2"
                                />
                                <button
                                    onClick={clearFilters}
                                    className={`font-bold py-2 px-4 rounded transition-colors duration-200 ${isFilterActive
                                        ? 'bg-orange-500 hover:bg-orange-600 text-white'
                                        : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                                        }`}
                                    disabled={!isFilterActive}
                                >
                                    Clear Filters
                                </button>
                            </div>
                            <button
                                onClick={markAllAsRead}
                                className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded"
                            >
                                Mark All as Read
                            </button>
                        </div>
                        {dateError && <p className="text-red-500 mb-2">{dateError}</p>}
                        {filteredNotifications.length === 0 ? (
                            <div className="flex flex-col items-center justify-center bg-white rounded-lg shadow-md p-8">
                                <IoNotificationsOffOutline className="text-6xl text-gray-400 mb-4" />
                                <h2 className="text-2xl font-semibold text-gray-700 mb-2">No Notifications</h2>
                                <p className="text-gray-500 text-center">
                                    {isFilterActive
                                        ? "No notifications match your current filter settings."
                                        : "You don't have any notifications at the moment."}
                                </p>
                            </div>
                        ) : (
                            <div className="notification-list-inside">
                                {renderNotifications(filteredNotifications)}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default NotificationsPage;
