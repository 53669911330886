import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Layout from '../common/Layout.jsx';
import Select from 'react-select';
import { deleteTask, getAllData, fetchTaskLifestyleConfig, getDataWithQuery } from '../../firebase/firetstore.js';
import { logError } from '../../services/errorLogService';
import { auth } from '../../firebase/firebaseConfig.js';
import { useAuth } from '../../context/AuthContext';
import SideMenu from '../common/SideMenu.jsx';
import { FaFilter, FaTimes, FaSort, FaTrash, FaEye, FaBars } from 'react-icons/fa';
import Loader from '../common/Loader';
import { toast, ToastContainer } from 'react-toastify';
import DeleteConfirmationModal from '../common/DeleteConfirmationModal';
import Pagination from '../common/Pagination';
import { useFilter } from '../../context/FilterContext';

// Add this component before the main TaskList component


const TaskList = () => {
    const navigate = useNavigate();
    const { user } = useAuth();
    const {
        activeDateFilter,
        setActiveDateFilter,
        selectedProjects,
        setSelectedProjects,
        selectedStatuses,
        setSelectedStatuses,
        sortOrder,
        setSortOrder,
        fromDate,
        setFromDate,
        toDate,
        setToDate,
        clearAllFilters
    } = useFilter();

    const [tasks, setTasks] = useState([]);
    const [projects, setProjects] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [taskToDelete, setTaskToDelete] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [tasksPerPage] = useState(10);
    const [minDate, setMinDate] = useState('');
    const [maxDate, setMaxDate] = useState('');
    const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false);
    const [filterSidebarPosition, setFilterSidebarPosition] = useState('-translate-x-full');
    const [dateError, setDateError] = useState('');

    const getDateRange = (filter) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        const weekStart = new Date(today);
        weekStart.setDate(weekStart.getDate() - weekStart.getDay());
        const weekEnd = new Date(weekStart);
        weekEnd.setDate(weekEnd.getDate() + 6);

        switch (filter) {
            case 'yesterday':
                return {
                    start: yesterday,
                    end: new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate(), 23, 59, 59, 999)
                };
            case 'today':
                return {
                    start: today,
                    end: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59, 999)
                };
            case 'this_week':
                return {
                    start: weekStart,
                    end: weekEnd
                };
            default:
                return null;
        }
    };

    useEffect(() => {
        setFilterSidebarPosition(isFilterSidebarOpen ? 'translate-x-0' : '-translate-x-full');
    }, [isFilterSidebarOpen]);

    useEffect(() => {
        const fetchData = async () => {
            if (auth.currentUser) {
                setIsLoading(true);
                try {
                    await Promise.all([fetchTasks(), fetchProjects()]);
                } catch (error) {
                    console.error("Error fetching data:", error);
                } finally {
                    setIsLoading(false);
                }
            } else {
                navigate('/');
            }
        };

        fetchData();
        handleDateFilterChange(activeDateFilter)
    }, [navigate]);

    const fetchTasks = async () => {
        try {
            const collectionPath = 'task_list';
            const userEmail = auth.currentUser.email;
            const allTasks = await getDataWithQuery(collectionPath, [
                { field: 'assigned_to', operator: '==', value: userEmail }
            ]);

            const formattedTasks = allTasks.map(task => ({
                id: task.id,
                title: task.title,
                description: task.description,
                status: task.status,
                estimate: `${task.estimation}`,
                estimation_unit: task.estimation_unit,
                projectName: task.project_name,
                createdAt: new Date(task.created_at.seconds * 1000),
                updatedAt: task.updated_at ? new Date(task.updated_at.seconds * 1000) : null,
                assigned: task.assigned,
                assignedTo: task.assigned_to,
                creatorEmail: task.creator_email,
            }));

            const sortedTasks = formattedTasks.sort((a, b) => {
                const dateA = a.updatedAt || a.createdAt;
                const dateB = b.updatedAt || b.createdAt;
                return dateB - dateA;
            });
            setTasks(sortedTasks);
            setProjects(user.projects);
        } catch (error) {
            await logError('fetchTasks', error.message, {
                collection: 'task_list',
                query: { field: 'assigned_to', value: auth.currentUser?.email },
                component: 'TaskList'
            });
        }
    };

    const fetchProjects = async () => {
        try {
            const lifestyles = await fetchTaskLifestyleConfig("configs", "task_life_cycle");
            setStatuses(lifestyles?.task_life_cycle)
        } catch (error) {
            console.error("Error fetching projects:", error);
        }
    };

    const handleSortByCreatedAt = () => {
        const newSortOrder = sortOrder === 'desc' ? 'asc' : 'desc';
        setSortOrder(newSortOrder);
        const sortedTasks = [...tasks].sort((a, b) => {
            if (newSortOrder === 'asc') {
                return new Date(a.createdAt) - new Date(b.createdAt);
            } else {
                return new Date(b.createdAt) - new Date(a.createdAt);
            }
        });
        setTasks(sortedTasks);
    };

    const DateFilterTabs = ({ activeDateFilter, handleDateFilterChange, className = "", isOutside = false }) => {
        const baseButtonClass = "px-4 py-2 rounded-full font-medium transition-colors duration-200";
        const activeClass = "bg-orange-500 text-white shadow-md";
        const inactiveClass = "bg-gray-200 text-gray-700 hover:bg-gray-300";

        const getButtonClass = (filter) => {
            return `${baseButtonClass} ${activeDateFilter === filter ? activeClass : inactiveClass} ${isOutside ? 'text-sm md:text-base' : 'text-xs'}`;
        };

        return (
            <div className={`flex flex-wrap gap-2 ${className} ${isOutside ? 'p-3 rounded-lg' : 'bg-gray-50 p-2 rounded-md'}`}>
                <button
                    className={getButtonClass('today')}
                    onClick={() => handleDateFilterChange('today')}
                >
                    Today
                </button>
                <button
                    className={getButtonClass('yesterday')}
                    onClick={() => handleDateFilterChange('yesterday')}
                >
                    Yesterday
                </button>
                <button
                    className={getButtonClass('this_week')}
                    onClick={() => handleDateFilterChange('this_week')}
                >
                    This Week
                </button>
                <button
                    className={getButtonClass('all')}
                    onClick={() => handleDateFilterChange('all')}
                >
                    All
                </button>
            </div>
        );
    };

    const handleDateFilterChange = (filter) => {
        setActiveDateFilter(filter);
        setDateError(''); // Clear any previous errors

        if (filter === 'all') {
            // Check if there are stored dates in localStorage
            const savedFilters = JSON.parse(localStorage.getItem('taskListFilters')) || {};
            if (savedFilters.fromDate && savedFilters.toDate) {
                // If dates are stored, use them
                setFromDate(savedFilters.fromDate);
                setToDate(savedFilters.toDate);
            } else {
                // If no dates are stored, clear the dates
                setFromDate('');
                setToDate('');
            }
            // Always clear min and max dates for 'all' filter
            setMinDate('');
            setMaxDate('');
        } else {
            const range = getDateRange(filter);
            if (range) {
                const startDate = new Date(range.start.getTime() - range.start.getTimezoneOffset() * 60000);
                const endDate = new Date(range.end.getTime() - range.end.getTimezoneOffset() * 60000);
                const newFromDate = startDate.toISOString().split('T')[0];
                const newToDate = endDate.toISOString().split('T')[0];

                // Validate dates
                if (newFromDate > newToDate) {
                    setDateError('From date cannot be later than To date');
                    return;
                }

                setFromDate(newFromDate);
                setToDate(newToDate);

                if (filter === 'this_week') {
                    setMinDate(newFromDate);
                    setMaxDate(newToDate);
                } else {
                    setMinDate('');
                    setMaxDate('');
                }
            }
        }
    };

    const handleFromDateChange = (e) => {
        const newFromDate = e.target.value;
        setFromDate(newFromDate);
        setDateError('');

        if (toDate && newFromDate > toDate) {
            setDateError('From date cannot be later than To date');
        }
    };

    const handleToDateChange = (e) => {
        const newToDate = e.target.value;
        setToDate(newToDate);
        setDateError('');

        if (fromDate && newToDate < fromDate) {
            setDateError('To date cannot be earlier than From date');
        }
    };

    const handleDateFilter = () => {
        return tasks.filter(task => {
            let createdDate = task.createdAt instanceof Date ? task.createdAt : new Date(task.created_at.seconds * 1000);
            let updatedDate = task.updatedAt ?
                (task.updatedAt instanceof Date ? task.updatedAt : new Date(task.updatedAt.seconds * 1000))
                : createdDate;

            const relevantDate = new Date(Math.max(createdDate, updatedDate));
            relevantDate.setHours(0, 0, 0, 0);

            let from = fromDate ? new Date(fromDate) : new Date(0);
            from.setHours(0, 0, 0, 0);
            let to = toDate ? new Date(toDate) : new Date();
            to.setHours(23, 59, 59, 999);

            return relevantDate >= from && relevantDate <= to;
        });
    };

    const filteredTasks = handleDateFilter()
        .filter(task =>
            (selectedProjects.length === 0 || selectedProjects.includes(task.projectName)) &&
            (selectedStatuses.length === 0 || selectedStatuses.includes(task.status))
        )
        .sort((a, b) => {
            if (sortOrder === 'asc') {
                return new Date(a.createdAt) - new Date(b.createdAt);
            } else {
                return new Date(b.createdAt) - new Date(a.createdAt);
            }
        });

    const handleDeleteClick = (taskId) => {
        setTaskToDelete(taskId);
        setIsDeleteModalOpen(true);
    };

    // Get current tasks
    const indexOfLastTask = currentPage * tasksPerPage;
    const indexOfFirstTask = indexOfLastTask - tasksPerPage;
    const currentTasks = filteredTasks.slice(indexOfFirstTask, indexOfLastTask);

    // Change page
    const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

    const handleDeleteConfirm = async () => {
        if (taskToDelete) {
            setIsLoading(true);
            try {
                const id = taskToDelete.toString();
                const taskToDeleteDetails = tasks.find(task => task.id === taskToDelete);

                // Delete from global task list
                await deleteTask('task_list', id);

                setTasks(tasks.filter(task => task.id !== taskToDelete));
                setIsLoading(false);
                toast.success("Task deleted successfully!");
            } catch (error) {
                setIsLoading(false);
                toast.error("Task deletion failed!");
                console.error("Error deleting task:", error);
            } finally {
                setIsDeleteModalOpen(false);
                setTaskToDelete(null);
            }
        }
    };

    const handleDeleteCancel = () => {
        setIsDeleteModalOpen(false);
        setTaskToDelete(null);
    };

    const handleTaskClick = (taskId) => {
        navigate(`/task/${taskId}`);
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };

    const projectOptions = projects.map(project => ({ value: project, label: capitalizeFirstLetter(project) }));
    const statusOptions = statuses.map(status => ({ value: status, label: status }));

    const handleClearAllFilters = () => {
        clearAllFilters();
        handleDateFilterChange('today');
    };

    const customSelectStyles = {
        control: (provided) => ({
            ...provided,
            borderColor: '#d1d5db',
            boxShadow: 'none',
            '&:hover': {
                borderColor: '#9ca3af',
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#f97316' : state.isFocused ? '#fed7aa' : null,
            color: state.isSelected ? 'white' : '#1f2937',
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: '#fed7aa',
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color: '#9a3412',
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            color: '#9a3412',
            '&:hover': {
                backgroundColor: '#f97316',
                color: 'white',
            },
        }),
    };

    return (
        <Layout>
            <style>
                {`
                    .date-input-grey::-webkit-datetime-edit { color: #999; }
                    .date-input-grey::-moz-placeholder { color: #999; }
                    .date-input-grey:-ms-input-placeholder { color: #999; }
                    .date-input-grey::placeholder { color: #999; }
                `}
            </style>
            <ToastContainer
                position='top-center'
                autoClose={5000}
                hideProgressBar={false}
                closeOnClick
                pauseOnHover
                draggable
                theme='light'
            />
            <div className="flex w-full h-[calc(100vh-4rem)]">
                <SideMenu />
                <div className="flex-1 overflow-hidden relative">
                    <div className="h-14 flex items-center justify-between px-6 border-b border-gray-200 bg-gradient-to-r from-orange-500 to-orange-600">
                        <h1 className="text-2xl font-bold text-white">Task List</h1>
                    </div>
                    <div className="p-6 bg-gray-50 h-[calc(100vh-4rem-3.5rem)] overflow-y-auto">
                        <div className="flex items-center justify-between mb-6">
                            <div className="flex items-center">
                                <button
                                    onClick={() => setIsFilterSidebarOpen(!isFilterSidebarOpen)}
                                    className="p-2 bg-white rounded-full shadow-md hover:bg-gray-100 transition-colors duration-200 mr-4"
                                >
                                    <FaBars className="text-orange-500" />
                                </button>
                                <DateFilterTabs
                                    activeDateFilter={activeDateFilter}
                                    handleDateFilterChange={handleDateFilterChange}
                                    isOutside={true}
                                />
                            </div>
                        </div>
                        {isLoading ? (
                            <div className="flex justify-center items-center h-full">
                                <Loader />
                            </div>
                        ) : (
                            <div className="bg-white shadow-md rounded-lg overflow-hidden">
                                <div className="overflow-x-auto">
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-900">
                                            <tr>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Id</th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider w-1/3">Title</th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Status</th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                                                    Estimate
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Project</th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                                                    Last Modified
                                                    <button onClick={handleSortByCreatedAt} className="ml-2 focus:outline-none">
                                                        <FaSort className={`inline ${sortOrder === 'desc' ? 'text-gray-400' : 'text-white'}`} />
                                                    </button>
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200">
                                            {currentTasks.map((task, index) => (
                                                <tr key={task.id} className="hover:bg-gray-50 transition-colors">
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                        <Link to={`/task/${task.id}`} className="text-blue-600 hover:text-blue-800 hover:underline">
                                                            {task.id}
                                                        </Link>
                                                    </td>
                                                    <td className="px-4 py-4">
                                                        <div className="text-sm font-semibold text-gray-900 line-clamp-2 break-words" title={task.title}>
                                                            {task.title}
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${task.status === 'completed' ? 'bg-green-100 text-green-800' :
                                                            task.status === 'in progress' ? 'bg-yellow-100 text-yellow-800' :
                                                                'bg-red-100 text-red-800'
                                                            }`}>
                                                            {task.status}
                                                        </span>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm  text-gray-500">{task.estimate}{task.estimation_unit}</td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{capitalizeFirstLetter(task.projectName)}</td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        {task.updatedAt ?
                                                            task.updatedAt.toLocaleDateString() :
                                                            task.createdAt.toLocaleDateString()}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                                                        <Link to={`/task/${task.id}`} className="text-indigo-600 hover:text-indigo-900 mr-2">
                                                            <FaEye className="inline" />
                                                        </Link>
                                                        <button onClick={() => handleDeleteClick(task.id)} className="text-red-600 hover:text-red-900">
                                                            <FaTrash className="inline" />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                {!isLoading && filteredTasks.length > 0 && (
                                    <div className="px-6 py-3 bg-gray-50 border-t border-gray-200">
                                        <Pagination
                                            currentPage={currentPage}
                                            totalPages={Math.ceil(filteredTasks.length / tasksPerPage)}
                                            onPageChange={handlePageChange}
                                        />
                                    </div>
                                )}
                                {filteredTasks.length === 0 && (
                                    <div className="flex flex-col justify-center items-center mt-8">
                                        <svg className="w-16 h-16 text-gray-400 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"></path>
                                        </svg>
                                        <p className="text-xl text-gray-500 font-semibold">No Tasks Found</p>
                                        <p className="text-sm text-gray-400 mt-2">There are no tasks available at the moment.</p>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    {isFilterSidebarOpen && (
                        <div className={`absolute top-14 left-0 w-80 h-[calc(100vh-4rem-3.5rem)] bg-white shadow-lg overflow-y-auto p-6 transition-all duration-300 ease-in-out transform ${filterSidebarPosition}`}>
                            <div className="flex justify-between items-center mb-4">
                                <h2 className="text-lg font-semibold">Filters</h2>
                                <button
                                    onClick={() => setIsFilterSidebarOpen(false)}
                                    className="p-1 hover:bg-gray-100 rounded-full transition-colors duration-200"
                                >
                                    <FaTimes className="text-gray-500 hover:text-gray-700" />
                                </button>
                            </div>
                            <div className="space-y-4">
                                <div>
                                    <h3 className="font-medium mb-2">Date Range</h3>
                                    <DateFilterTabs
                                        activeDateFilter={activeDateFilter}
                                        handleDateFilterChange={handleDateFilterChange}
                                        className="flex-wrap"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">From</label>
                                    <input
                                        type="date"
                                        value={fromDate}
                                        onChange={handleFromDateChange}
                                        className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-transparent date-input-grey"
                                        disabled={activeDateFilter === 'yesterday' || activeDateFilter === 'today'}
                                        min={minDate}
                                        max={maxDate}
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">To</label>
                                    <input
                                        type="date"
                                        value={toDate}
                                        onChange={handleToDateChange}
                                        className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-transparent date-input-grey"
                                        disabled={activeDateFilter === 'yesterday' || activeDateFilter === 'today'}
                                        min={minDate}
                                        max={maxDate}
                                    />
                                </div>
                                {dateError && (
                                    <div className="text-red-500 text-sm mt-2">
                                        {dateError}
                                    </div>
                                )}

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">Projects</label>
                                    <Select
                                        isMulti
                                        options={projectOptions}
                                        value={projectOptions.filter(option => selectedProjects.includes(option.value))}
                                        onChange={(selectedOptions) => setSelectedProjects(selectedOptions.map(option => option.value))}
                                        placeholder="Filter by Project"
                                        className="w-64"
                                        styles={customSelectStyles}
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">Statuses</label>
                                    <Select
                                        isMulti
                                        options={statusOptions}
                                        value={statusOptions.filter(option => selectedStatuses.includes(option.value))}
                                        onChange={(selectedOptions) => setSelectedStatuses(selectedOptions.map(option => option.value))}
                                        placeholder="Filter by Status"
                                        className="w-64"
                                        styles={customSelectStyles}
                                    />
                                </div>
                                <div className="mt-6">
                                    <button
                                        onClick={handleClearAllFilters}
                                        className="w-25 bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition duration-300"
                                    >
                                        Clear All Filters
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <DeleteConfirmationModal
                isOpen={isDeleteModalOpen}
                onClose={handleDeleteCancel}
                onConfirm={handleDeleteConfirm}
            />
        </Layout>
    );
};

export default TaskList;
