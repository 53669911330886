import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../components/common/Layout';
import SideMenu from '../components/common/SideMenu';
import { useAuth } from '../context/AuthContext';
import { FaUser, FaEnvelope, FaProjectDiagram, FaSearch, FaUserEdit } from 'react-icons/fa';
import api from '../api';
import { ToastContainer, toast } from 'react-toastify';
import Pagination from '../components/common/Pagination';  // Add this import


const TeamPage = () => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const { user } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [membersPerPage] = useState(10);  // You can adjust this number as needed

  useEffect(() => {
    fetchTeamMembers();
  }, [user.email]);

  useEffect(() => {
    filterMembers();
  }, [searchTerm, teamMembers]);

  const fetchTeamMembers = async () => {
    try {
      const response = await api.get(`api/team/${user.email}`);
      setTeamMembers(response.data);
      setLoading(false);
    } catch (error) {
      toast.error(error.response?.data?.error || 'Error fetching team members');
      console.error('Error fetching team members:', error);
      setLoading(false);
    }
  };

  const filterMembers = () => {
    const filtered = teamMembers.filter(member =>
      member.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      member.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      member.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      member.role.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (member.projects && member.projects.some(project =>
        project.toLowerCase().includes(searchTerm.toLowerCase())
      ))
    );
    setFilteredMembers(filtered);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Get current members
  const indexOfLastMember = currentPage * membersPerPage;
  const indexOfFirstMember = indexOfLastMember - membersPerPage;
  const currentMembers = filteredMembers.slice(indexOfFirstMember, indexOfLastMember);


  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  // Change page
  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <Layout>
      <ToastContainer position="top-right" autoClose={3000} />
      <div className="flex w-full h-[calc(100vh-4rem)]">
        <SideMenu />
        <div className="flex-1 overflow-y-auto">
          <div className='h-14 flex items-center justify-between px-6 border-b border-gray-200 bg-gradient-to-r from-orange-500 to-orange-600'>
            <h1 className='font-semibold text-xl text-white'>My Team</h1>
          </div>
          <div className='p-6 bg-gray-50'>
            <div className="mb-4 flex items-center bg-white rounded-lg shadow-md p-3">
              <FaSearch className="text-gray-400 mr-2" />
              <input
                type="text"
                placeholder="Search team members..."
                className="w-full outline-none"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
            {loading ? (
              <div className="flex justify-center items-center h-64">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-orange-500"></div>
              </div>
            ) : filteredMembers.length === 0 ? (
              <div className="bg-white shadow-md rounded-lg p-6 text-center">
                <FaUser className="mx-auto text-5xl text-gray-300 mb-4" />
                <h2 className="text-2xl font-semibold text-gray-700 mb-2">No Team Members Found</h2>
                <p className="text-gray-500">
                  {searchTerm ? "No members match your search criteria." : "There are no team members to display."}
                </p>
              </div>
            ) : (
              <div className="bg-white shadow-md rounded-lg overflow-hidden">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-orange-100">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-orange-800 uppercase tracking-wider">Name</th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-orange-800 uppercase tracking-wider">Email</th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-orange-800 uppercase tracking-wider">Role</th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-orange-800 uppercase tracking-wider">Projects</th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-orange-800 uppercase tracking-wider">Actions</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {currentMembers.map((member) => (
                      <tr key={member.id} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap">
                          {/* <div className="flex items-center"> */}
                          {/* <div className="flex-shrink-0 h-10 w-10">
                              <FaUser className="h-10 w-10 rounded-full text-gray-300" />
                            </div> */}
                          <div className="ml-4">
                            <div className="text-sm font-medium text-gray-900">{`${member.first_name} ${member.last_name}`}</div>
                            {/* </div> */}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <FaEnvelope className="text-gray-400 mr-2" />
                            <div className="text-sm text-gray-900">{member.email}</div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                            {capitalizeFirstLetter(member.role)}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <FaProjectDiagram className="text-gray-400 mr-2 flex-shrink-0" />
                            <div className="text-sm text-gray-900 truncate max-w-[200px]" title={Array.isArray(member.projects)
                              ? member.projects.map(project => capitalizeFirstLetter(project)).join(', ')
                              : capitalizeFirstLetter(member.project || '')}>
                              {Array.isArray(member.projects)
                                ? member.projects.map(project => capitalizeFirstLetter(project)).join(', ')
                                : capitalizeFirstLetter(member.project || '')}
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                          <Link
                            to={`/user/${member.id}`}
                            state={{ fromTeamListing: true }}
                            className="text-orange-600 hover:text-orange-900"
                          >
                            <FaUserEdit className="inline mr-1" />
                            Edit
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="px-6 py-4">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={Math.ceil(filteredMembers.length / membersPerPage)}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TeamPage;