import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';

const FilterContext = createContext();

export const useFilter = () => {
    return useContext(FilterContext);
};

export const FilterProvider = ({ children }) => {
    const [filters, setFilters] = useState(() => {
        const savedFilters = JSON.parse(localStorage.getItem('taskListFilters')) || {};
        return {
            activeDateFilter: savedFilters.activeDateFilter || 'today',
            selectedProjects: savedFilters.selectedProjects || [],
            selectedUsers: savedFilters.selectedUsers || [],
            selectedStatuses: savedFilters.selectedStatuses || [],
            sortOrder: savedFilters.sortOrder || 'desc',
            fromDate: ['all'].includes(savedFilters.activeDateFilter) ? (savedFilters.fromDate || '') : '',
            toDate: ['all'].includes(savedFilters.activeDateFilter) ? (savedFilters.toDate || '') : '',
            activeProjectTab: savedFilters.activeProjectTab || '',
            activeRoleTab: savedFilters.activeRoleTab || 'lead',
        };
    });

    useEffect(() => {
        const filtersToSave = { ...filters };
        if (!['all'].includes(filters.activeDateFilter)) {
            delete filtersToSave.fromDate;
            delete filtersToSave.toDate;
        }

        // Only save to localStorage if we're on a task-related page
        const isTaskRelatedPage = window.location.pathname.includes('/task-list') || /^\/task\/[^/]+$/.test(window.location.pathname);
        if (isTaskRelatedPage) {
            localStorage.setItem('taskListFilters', JSON.stringify(filtersToSave));
        }
    }, [filters]);

    const updateFilter = useCallback((filterName, value) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [filterName]: value
        }));
    }, []);

    const clearAllFilters = useCallback(() => {
        setFilters({
            activeDateFilter: 'today',
            selectedProjects: [],
            selectedUsers: [],
            selectedStatuses: [],
            sortOrder: 'desc',
            fromDate: '',
            toDate: '',
            activeProjectTab: '',
            activeRoleTab: 'lead',
        });
    }, []);

    const clearFiltersAndStorage = useCallback(() => {
        clearAllFilters();
        localStorage.removeItem('taskListFilters');

        // Double-check if the item was removed
        const checkStorage = localStorage.getItem('taskListFilters');
        if (checkStorage === null) {
        }

        // Force update the filters state to trigger a re-render
        setFilters({
            activeDateFilter: 'today',
            selectedProjects: [],
            selectedUsers: [],
            selectedStatuses: [],
            sortOrder: 'desc',
            fromDate: '',
            toDate: '',
            activeProjectTab: '',
            activeRoleTab: 'lead',
        });
    }, [clearAllFilters, setFilters]);

    const value = {
        ...filters,
        setActiveDateFilter: useCallback((value) => updateFilter('activeDateFilter', value), [updateFilter]),
        setSelectedProjects: useCallback((value) => updateFilter('selectedProjects', value), [updateFilter]),
        setSelectedUsers: useCallback((value) => updateFilter('selectedUsers', value), [updateFilter]),
        setSelectedStatuses: useCallback((value) => updateFilter('selectedStatuses', value), [updateFilter]),
        setSortOrder: useCallback((value) => updateFilter('sortOrder', value), [updateFilter]),
        setFromDate: useCallback((value) => updateFilter('fromDate', value), [updateFilter]),
        setToDate: useCallback((value) => updateFilter('toDate', value), [updateFilter]),
        setActiveProjectTab: useCallback((value) => updateFilter('activeProjectTab', value), [updateFilter]),
        setActiveRoleTab: useCallback((value) => updateFilter('activeRoleTab', value), [updateFilter]),
        clearAllFilters,
        clearFiltersAndStorage
    };

    return (
        <FilterContext.Provider value={value}>
            {children}
        </FilterContext.Provider>
    );
};
