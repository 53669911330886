import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { auth } from '../../firebase/firebaseConfig';
import { signOut } from 'firebase/auth';
import { getFirestore, collection, query, where, onSnapshot, doc, updateDoc } from 'firebase/firestore';
import { FaBell } from 'react-icons/fa';
import '../../styles/style.css'

const Layout = ({ children }) => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [notifications, setNotifications] = useState([]);
    const [showNotifications, setShowNotifications] = useState(false);

    useEffect(() => {
        if (user) {
            const db = getFirestore();
            const notificationsRef = collection(db, `user_details/${user.email}/notifications`);
            const q = query(notificationsRef, where('status', '==', 'new'));

            const unsubscribe = onSnapshot(q, (snapshot) => {
                const newNotifications = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setNotifications(newNotifications);
            });

            return () => unsubscribe();
        }
    }, [user]);

    const handleLogout = async () => {
        try {
            await signOut(auth);
            navigate('/'); // Redirect to login page after logout
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };

    const markAsRead = async (notificationId) => {
        const db = getFirestore();
        const notificationRef = doc(db, `user_details/${user.email}/notifications`, notificationId);
        await updateDoc(notificationRef, { status: 'read' });
    };

    const handleNotificationClick = () => {
        navigate('/notifications');
    };

    return (
        <div className="layout">
            {/* Header */}
            <header className="header">
                <div className="header-content">
                    {/* Logo */}
                    <div className="logo">
                        <img
                            src="/codem-logo.webp"
                            alt="Codem Logo"
                            className="logo-image"
                        />
                    </div>

                    {/* Title */}
                    <div className="title">
                        <h1>Task Tracker</h1>
                    </div>

                    {/* User Profile, Notifications, and Logout */}
                    {user && (
                        <div className="user-profile">
                            <div className="flex flex-col items-start mr-4">
                                <span className="text-sm font-semibold text-white break-words max-w-[120px]">
                                    <span className="text-sm text-gray-300">Hello,</span> {user.first_name || 'Admin'}
                                </span>
                            </div>

                            {/* Notification Icon */}
                            <div className="notification-icon" onClick={handleNotificationClick}>
                                <FaBell
                                    className="bell-icon"
                                />
                                {notifications.length > 0 && (
                                    <span className="notification-count">{notifications.length}</span>
                                )}
                                {/* {showNotifications && (
                                    <div className="notification-popup">
                                        <div className="notification-list">
                                            {notifications.length > 0 ? (
                                                notifications.map((notification) => (
                                                    <div key={notification.id} className="notification-item">
                                                        <p className={notification.status === 'new' ? 'bold' : ''}>
                                                            {notification.message}
                                                        </p>
                                                        <button
                                                            onClick={() => markAsRead(notification.id)}
                                                            className="mark-read-button"
                                                        >
                                                            Mark Read
                                                        </button>
                                                    </div>
                                                ))
                                            ) : (
                                                <p className="no-notifications">No new notifications</p>
                                            )}
                                        </div>
                                    </div>
                                )} */}
                            </div>

                            <div className="user-avatar">
                                <span>{user.first_name ? user.first_name[0].toUpperCase() : 'U'}</span>
                            </div>
                            <button
                                onClick={handleLogout}
                                className="logout-button"
                            >
                                Logout
                            </button>
                        </div>
                    )}
                </div>
            </header>

            {/* Main Content */}
            <main className="main-content">
                {children}
            </main>
        </div>
    );
};

export default Layout;