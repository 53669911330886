import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../components/common/Layout';
import SideMenu from '../components/common/SideMenu';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../api';
import * as XLSX from 'xlsx';
import { FaDownload } from 'react-icons/fa';
import { getFirestore, collection, getDocs, query, where } from 'firebase/firestore';
import { getAllData } from '../firebase/firetstore';
import Loader from '../components/common/Loader';
import Pagination from '../components/common/Pagination';
import { logError } from '../services/errorLogService';

const ImportUser = () => {
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [importedData, setImportedData] = useState([]);
  const [isFileValid, setIsFileValid] = useState(false);
  const navigate = useNavigate();
  const [projectList, setProjectList] = useState([]);
  const [validatedData, setValidatedData] = useState([]);
  const expectedHeaders = ['email', 'first_name', 'last_name', 'manager_email', 'password', 'role', 'projects'];

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    try {
      const projects = await getAllData('projects');
      setProjectList(projects.map(project => project.project_name));
    } catch (error) {
      await logError('fetch-projects', error.message, {
        collection: 'projects',
        component: 'importUser'
      });
      toast.error('Failed to fetch projects');
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setIsFileValid(false);
    setImportedData([]);
  };

  const readFileAsync = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => resolve(e.target.result);
      reader.onerror = (e) => reject(e);
      reader.readAsBinaryString(file);
    });
  };

  const validateFileContent = (data) => {
    const workbook = XLSX.read(data, { type: 'binary' });
    const sheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[sheetName];
    const parsedData = XLSX.utils.sheet_to_json(sheet, { defval: "" }); // Set default value to empty string

    if (parsedData.length === 0) {
      return { isValid: false, parsedData: [], error: "The file is empty" };
    }

    const headers = Object.keys(parsedData[0]);
    const isValid = expectedHeaders.every(header => headers.includes(header));

    return { isValid, parsedData };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      toast.error('Please select a file to import');
      return;
    }

    setIsLoading(true);

    try {
      const data = await readFileAsync(file);
      const { isValid, parsedData, error } = validateFileContent(data);

      if (!isValid) {
        toast.error(error || 'Please upload a valid file with the correct headers');
        setIsLoading(false);
        return;
      }

      const validatedData = await validateImportedData(parsedData);
      setImportedData(validatedData);
      setIsFileValid(true);
    } catch (error) {
      console.error('Error reading or validating file:', error);
      toast.error('Failed to read or validate the file. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const validateImportedData = async (data) => {
    const db = getFirestore();
    const userDetailsRef = collection(db, 'user_details');
    const validatedData = await Promise.all(data.map(async (user) => {
      let isValid = true;
      let errors = [];

      // Check if user already exists
      const userQuery = query(userDetailsRef, where('email', '==', user.email));
      const userSnapshot = await getDocs(userQuery);
      if (!userSnapshot.empty) {
        isValid = false;
        errors.push('User already exists');
      }

      // Validate projects
      const userProjects = user.projects ? user.projects.split(',').map(p => p.trim().toLowerCase()) : [];
      const invalidProjects = userProjects.filter(p => !projectList.includes(p));
      if (invalidProjects.length > 0) {
        isValid = false;
        errors.push(`Invalid projects: ${invalidProjects.join(', ')}`);
      }

      // Validate manager email
      if (['developer', 'lead'].includes(user.role.toLowerCase())) {
        if (!user.manager_email) {
          isValid = false;
          errors.push('Manager email is required for developer or lead');
        }
      } else if (user.role.toLowerCase() === 'manager' && user.manager_email) {
        isValid = false;
        errors.push('Manager should not have a manager email');
      }

      // Additional validations
      if (!user.email) {
        isValid = false;
        errors.push('Email is required');
      }
      if (!user.first_name) {
        isValid = false;
        errors.push('First name is required');
      }
      if (!user.last_name) {
        isValid = false;
        errors.push('Last name is required');
      }
      if (!user.role) {
        isValid = false;
        errors.push('Role is required');
      }

      return { ...user, isValid, errors };
    }));

    setValidatedData(validatedData);
    return validatedData;
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10);

  const handleUpload = async () => {
    const validUsers = importedData.filter(user => user.isValid);
    const invalidUsers = importedData.filter(user => !user.isValid);

    if (invalidUsers.length > 0) {
      toast.error('Please upload valid data. There are invalid users in the file.');
      return;
    }

    if (validUsers.length === 0) {
      toast.error('No valid users to upload. Please check your data and try again.');
      return;
    }

    setIsLoading(true);
    try {
      const response = await api.post('api/users/import', { users: validUsers });

      if (response.data.success) {
        toast.success('Users imported successfully!');
        navigate('/admin-dashboard');
      } else {
        throw new Error(response.data.error || 'Failed to import users');
      }
    } catch (error) {
      console.error('Error importing users:', error);
      toast.error(error.message || 'Failed to import users');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadSample = () => {
    const sampleData = [
      {
        email: 'sample@example.com',
        first_name: 'John',
        last_name: 'Doe',
        manager_email: 'manager@example.com',
        password: "password",
        role: 'developer',
        projects: 'project A, project B'
      }
    ];

    const ws = XLSX.utils.json_to_sheet(sampleData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Users");
    XLSX.writeFile(wb, "sample_users_file.xlsx");
  };

  const generateUserData = () => {
    const baseData = [
      {
        email: "usercodem1@mailinator.com",
        first_name: "user",
        last_name: "codem1",
        manager_email: "managercodem@mailinator.com",
        password: "usercodem1@mailinator.com",
        role: "developer",
        projects: "norwex,tapouts"
      },
      {
        email: "usercodem2@mailinator.com",
        first_name: "user",
        last_name: "codem2",
        manager_email: "managercodem@mailinator.com",
        password: "usercodem2@mailinator.com",
        role: "developer",
        projects: "norwex"
      },
      {
        email: "usercodem3@mailinator.com",
        first_name: "user",
        last_name: "codem3",
        manager_email: "managercodem@mailinator.com",
        password: "usercodem3@mailinator.com",
        role: "lead",
        projects: "norwex,tapouts"
      },
      {
        email: "usercodem4@mailinator.com",
        first_name: "user",
        last_name: "codem4",
        manager_email: "managercodem@mailinator.com",
        password: "usercodem4@mailinator.com",
        role: "lead",
        projects: "norwex"
      }
    ];

    const users = [];
    for (let i = 1; i <= 30; i++) {
      const template = baseData[Math.floor(Math.random() * baseData.length)];
      const user = {
        ...template,
        email: `usercodem${i}@mailinator.com`,
        last_name: `codem${i}`,
        password: `usercodem${i}@mailinator.com`,
        role: Math.random() < 0.5 ? "developer" : "lead",
        projects: Math.random() < 0.5 ? "norwex" : "norwex,tapouts"
      };
      users.push(user);
    }
    return users;
  };

  const downloadExcel = () => {
    const users = generateUserData();
    const worksheet = XLSX.utils.json_to_sheet(users);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');

    // Generate and download the Excel file
    XLSX.writeFile(workbook, 'generated_users.xlsx');
  };

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = importedData.slice(indexOfFirstUser, indexOfLastUser);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <Layout>
      <ToastContainer position="top-right" autoClose={3000} />
      <div className="flex w-full h-[calc(100vh-4rem)]">
        <SideMenu />
        <div className="flex-1">
          <div className='h-14 flex items-center justify-between px-6 border-b border-gray-200 bg-gradient-to-r from-orange-500 to-orange-600'>
            <h1 className='font-semibold text-xl text-white'>Import Users</h1>
          </div>
          <div className='p-6 bg-gray-50 h-[calc(100vh-4rem-3.5rem)] overflow-y-auto'>
            {isLoading ? (
              <div className="flex justify-center items-center h-full">
                <Loader />
              </div>
            ) : (
              <>
                <div className="bg-white p-6 rounded-lg shadow mx-auto">
                  <div className="mb-4">
                    <button
                      onClick={handleDownloadSample}
                      className="flex items-center text-blue-600 hover:text-blue-800"
                    >
                      <span className="mr-2 text-sm font-semibold">Click here to download the sample file</span>
                      <FaDownload />
                    </button>
                  </div>

                  <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                      <label className="block mb-1 text-sm font-semibold text-gray-600">Select csv or xlsx File:</label>
                      <input
                        type="file"
                        accept=".csv,.xlsx,.xls"
                        onChange={handleFileChange}
                        className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                      />
                    </div>
                    {!isFileValid ? (
                      <button
                        className="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="submit"
                        disabled={isLoading || !file}
                      >
                        Import
                      </button>
                    ) : (
                      <button
                        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="button"
                        onClick={handleUpload}
                        disabled={isLoading || importedData.some(user => !user.isValid)}
                      >
                        Upload
                      </button>
                    )}
                  </form>
                </div>
                {importedData.length > 0 && (
                  <div className="mt-8 bg-white p-6 rounded-lg shadow mx-auto">
                    <h2 className="text-xl font-semibold mb-4">Imported Data</h2>
                    {importedData.some(user => !user.isValid) && (
                      <p className="text-red-500 text-sm font-semibold mb-4">
                        There are invalid users in the imported data. Please correct the errors before uploading.
                      </p>
                    )}
                    <div className="overflow-x-auto">
                      <table className="w-full table-auto">
                        <thead className="bg-gray-50">
                          <tr>
                            <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                            <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                            <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Manager</th>
                            <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Role</th>
                            <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Projects</th>
                            <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {currentUsers.map((user, index) => (
                            <tr key={index} className={user.isValid ? 'bg-green-50' : 'bg-red-50'}>
                              <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">{user.email}</td>
                              <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">{`${user.first_name} ${user.last_name}`}</td>
                              <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">{user.manager_email || '-'}</td>
                              <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">{user.role}</td>
                              <td className="px-3 py-2 text-sm text-gray-500 max-w-xs truncate">{user.projects}</td>
                              <td className="px-3 py-2 text-sm">
                                {user.isValid ? (
                                  <span className="text-green-600 font-semibold">Valid</span>
                                ) : (
                                  <span className="text-red-600 font-semibold">
                                    Invalid: {user.errors.join(', ')}
                                  </span>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <Pagination
                      currentPage={currentPage}
                      totalPages={Math.ceil(importedData.length / usersPerPage)}
                      onPageChange={handlePageChange}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ImportUser;