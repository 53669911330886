import { getFirestore, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { auth } from '../firebase/firebaseConfig';

export const logError = async (processName, errorMessage, additionalInfo = {}) => {
  try {
    const db = getFirestore();
    const errorLogsRef = collection(db, 'error_logs');

    const errorLog = {
      process: processName,
      error_message: errorMessage,
      created_at: serverTimestamp(),
      additional_info: {
        ...additionalInfo,
        user_email: auth.currentUser?.email || 'anonymous'
      },
      environment: process.env.ERROR_LOG_ENV || 'development',
    };

    await addDoc(errorLogsRef, errorLog);
    console.error(`Error logged - Process: ${processName}, Error: ${errorMessage}`);
  } catch (err) {
    console.error('Failed to log error to Firebase:', err);
  }
};
