import React, { useState, useEffect, useRef } from 'react';
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  collection,
  serverTimestamp,
  getDocs,
  query,
  where,
  Timestamp,
} from 'firebase/firestore';
import { useAuth } from '../context/AuthContext';
import { toast, ToastContainer } from 'react-toastify';
import Layout from '../components/common/Layout';
import SideMenu from '../components/common/SideMenu';
import {
  FaTimes,
  FaFile,
  FaFileImage,
  FaFilePdf,
  FaFileWord,
  FaFileExcel,
  FaUpload,
  FaTrash,
} from 'react-icons/fa';
import {
  uploadTaskFiles,
  getTaskFiles,
} from '../services/storageService';
import DeleteConfirmationModal from '../components/common/DeleteConfirmationModal';
import { logError } from '../services/errorLogService';

const CreateTask = () => {
  const [taskData, setTaskData] = useState({
    description: '',
    status: '',
    estimation: '',
    estimation_unit: 'h',
    project_name: '',
    title: '',
    assigned_to: '',
    start_time: '',
    end_time: '',
  });
  const [projects, setProjects] = useState([]);
  const [taskStatuses, setTaskStatuses] = useState([]);
  const [developers, setDevelopers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState({
    start: false,
    end: false,
  });
  const { user } = useAuth();
  const db = getFirestore();

  const startDateTimeRef = useRef(null);
  const endDateTimeRef = useRef(null);

  const [attachments, setAttachments] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const fileInputRef = useRef(null);

  const [isAttachDeleteModalOpen, setIsAttachDeleteModalOpen] = useState(false);
  const [removeIndex, setRemoveIndex] = useState(0);

  const handleFileSelection = (event) => {
    const newFiles = Array.from(event.target.files).map((file) => ({
      file,
      preview: file.type.startsWith('image/')
        ? URL.createObjectURL(file)
        : null,
    }));
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
    // Reset the file input value to allow selecting the same file again
    event.target.value = '';
  };

  const validateFiles = (files) => {
    const maxFileSize = 2 * 1024 * 1024; // 2MB in bytes
    const maxFiles = 10;

    if (files.length > maxFiles) {
      toast.error(`You can upload a maximum of ${maxFiles} files.`);
      return false;
    }

    for (let i = 0; i < files.length; i++) {
      if (files[i].file.size > maxFileSize) {
        toast.error(`File "${files[i].file.name}" exceeds the 2MB size limit.`);
        return false;
      }
    }

    return true;
  };

  const handleRemoveFile = () => {
    setSelectedFiles((prevFiles) => {
      const updatedFiles = prevFiles.filter((_, i) => i !== removeIndex);
      if (prevFiles[removeIndex]?.preview) {
        URL.revokeObjectURL(prevFiles[removeIndex].preview);
      }
      return updatedFiles;
    });
    setIsAttachDeleteModalOpen(false);
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const getFileIcon = (fileType) => {
    if (fileType.startsWith('image/')) return <FaFileImage />;
    if (fileType === 'application/pdf') return <FaFilePdf />;
    if (fileType.includes('word')) return <FaFileWord />;
    if (fileType.includes('excel') || fileType.includes('spreadsheet'))
      return <FaFileExcel />;
    return <FaFile />;
  };

  const fetchTaskFiles = async () => {
    try {
      const files = await getTaskFiles(taskData.id);
      setUploadedFiles(files);
    } catch (error) {
      await logError('firebase_fetchTaskFiles', error.message, {
        taskId: taskData.id,
        component: 'CreateTask'
      });
      toast.error('Failed to fetch task files');
    }
  };

  useEffect(() => {
    // Cleanup function to revoke object URLs when component unmounts
    return () => {
      selectedFiles.forEach((file) => {
        if (file.preview) {
          URL.revokeObjectURL(file.preview);
        }
      });
    };
  }, []);

  useEffect(() => {
    fetchProjects();
    fetchTaskStatuses();
    // fetchDevelopers() removed from here
  }, []);

  const fetchProjects = async () => {
    try {
      const db = getFirestore();
      const projectsCollection = collection(db, 'projects');
      const activeProjects = [];

      for (const projectName of user.projects) {
        const projectQuery = query(
          projectsCollection,
          where('project_name', '==', projectName)
        );
        const projectSnapshot = await getDocs(projectQuery);

        if (!projectSnapshot.empty) {
          const projectData = projectSnapshot.docs[0].data();
          if (
            projectData.project_status &&
            projectData.project_status.toLowerCase() !== 'in-active'
          ) {
            activeProjects.push(projectName);
          }
        }
      }

      setProjects(activeProjects);
    } catch (error) {
      await logError('firebase_fetchProjects', error.message, {
        userProjects: user.projects,
        component: 'CreateTask'
      });
      toast.error('Failed to fetch projects');
    }
  };

  const fetchTaskStatuses = async () => {
    try {
      const configDoc = await getDoc(doc(db, 'configs', 'task_life_cycle'));
      if (configDoc.exists()) {
        setTaskStatuses(configDoc.data().task_life_cycle);
        setTaskData((prevData) => ({
          ...prevData,
          status: configDoc.data().task_life_cycle[0],
        }));
      }
    } catch (error) {
      await logError('firebase_fetchTaskStatuses', error.message, {
        collection: 'configs',
        document: 'task_life_cycle',
        component: 'CreateTask'
      });
      toast.error('Failed to fetch task statuses');
    }
  };

  const fetchDevelopers = async (projectName) => {
    try {
      const usersCollection = collection(db, 'user_details');
      const usersQuery = query(
        usersCollection,
        where('role', 'in', ['developer', 'lead']),
        where('projects', 'array-contains', projectName)
      );
      const usersSnapshot = await getDocs(usersQuery);
      const developersList = usersSnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .map((developer) => ({
          email: developer.email,
          name: `${developer.first_name} ${developer.last_name} (${developer.email})`,
          isCurrentUser: developer.email === user.email,
        }));

      // Add the current user if they're not already in the list
      // if (!developersList.some(dev => dev.email === user.email)) {
      //   developersList.unshift({
      //     email: user.email,
      //     name: `${user.first_name} ${user.last_name} (${user.email}) (You)`,
      //     isCurrentUser: true
      //   });
      // }

      setDevelopers(developersList);
    } catch (error) {
      await logError('firebase_fetchDevelopers', error.message, {
        collection: 'user_details',
        projectName,
        user: user.email,
        component: 'CreateTask'
      });
      toast.error('Failed to fetch developers');
    }
  };

  useEffect(() => {
    if (taskData.start_time && taskData.estimation) {
      const startDate = new Date(taskData.start_time);
      const estimation = parseFloat(taskData.estimation);
      let endDate = new Date(startDate);

      switch (taskData.estimation_unit) {
        case 'h':
          endDate.setMinutes(
            endDate.getMinutes() + Math.round(estimation * 60)
          );
          break;
        case 'd':
          endDate.setHours(endDate.getHours() + Math.round(estimation * 24));
          break;
        case 'w':
          endDate.setHours(
            endDate.getHours() + Math.round(estimation * 7 * 24)
          );
          break;
        default:
          break;
      }

      // Convert to local ISO string
      const endDateLocal = new Date(
        endDate.getTime() - endDate.getTimezoneOffset() * 60000
      );
      const endDateString = endDateLocal.toISOString().slice(0, 16);

      setTaskData((prevData) => ({
        ...prevData,
        end_time: endDateString,
      }));
    }
  }, [taskData.start_time, taskData.estimation, taskData.estimation_unit]);

  useEffect(() => {
    if (taskData.id) {
      fetchTaskFiles();
    }
  }, [taskData.id]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setTaskData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === 'project_name') {
      if (value) {
        fetchDevelopers(value);
      } else {
        setDevelopers([]);
      }
    }

    // Clear end_time when start_time is emptied
    if (name === 'start_time' && value === '') {
      setTaskData((prevData) => ({
        ...prevData,
        end_time: '',
      }));
    }

    // Add date validation
    if (name === 'start_time' || name === 'end_time') {
      validateDates(name, value);
    }
  };

  const validateDates = (changedField, value) => {
    const start = changedField === 'start_time' ? value : taskData.start_time;
    const end = changedField === 'end_time' ? value : taskData.end_time;

    if (!start) {
      // If start date is empty, clear end date
      setTaskData((prevData) => ({
        ...prevData,
        end_time: '',
      }));
      return;
    }

    // if (start && end) {
    // if (new Date(end) <= new Date(start)) {
    //   toast.error('End date must be after the start date');
    //   setTaskData(prevData => ({
    //     ...prevData,
    //     end_time: ''
    //   }));
    //   setTaskData(prevData => ({
    //     ...prevData,
    //     [changedField]: ''
    //   }));
    // }
    // }
  };

  const validateField = () => {
    if (!taskData.project_name || taskData.project_name === '') {
      toast.error("Please provide a project for the task");
      setIsLoading(false);
      return false;
    }

    if (!taskData.title || taskData.title.trim() === '') {
      toast.error("Please provide a title for the task");
      setIsLoading(false);
      return false;
    }

    if (!taskData.description || taskData.description.trim() === '') {
      toast.error("Please provide a description for the task");
      setIsLoading(false);
      return false;
    }

    if (!taskData.status || taskData.status === '') {
      toast.error("Please select a status for the task");
      setIsLoading(false);
      return false;
    }

    if (!taskData.estimation || taskData.estimation === '') {
      toast.error("Please provide an estimation for the task");
      setIsLoading(false);
      return false;
    }

    if (!taskData.estimation_unit || taskData.estimation_unit === '') {
      toast.error("Please select an estimation unit for the task");
      setIsLoading(false);
      return false;
    }

    if ((user.role === 'lead' || user.role === 'manager') && (!taskData.assigned_to || taskData.assigned_to === '')) {
      toast.error("Please assign the task to a user");
      setIsLoading(false);
      return false;
    }

    if (taskData.status !== 'open' && (!taskData.start_time || taskData.start_time === '')) {
      toast.error("Please provide a start time for the task");
      setIsLoading(false);
      return false;
    }

    if (taskData.end_time && new Date(taskData.end_time) <= new Date(taskData.start_time)) {
      toast.error("End time must be after the start time");
      setIsLoading(false);
      return false;
    }
    setIsLoading(false);
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!validateField()) {
      return;
    }

    // Validate files
    if (!validateFiles(selectedFiles)) {
      setIsLoading(false);
      return;
    }

    try {
      // Fetch the project document using a query
      const projectsRef = collection(db, 'projects');
      const projectQuery = query(
        projectsRef,
        where('project_name', '==', taskData.project_name)
      );
      const projectSnapshot = await getDocs(projectQuery);
      if (projectSnapshot.empty) {
        throw new Error('Project not found');
      }

      const projectDoc = projectSnapshot.docs[0];
      const projectData = projectDoc.data();
      let nextTaskId = (projectData.task_id || 0) + 1;
      const projectPrefix = projectData.project_prefix;

      if (!projectPrefix) {
        throw new Error('Project prefix not found');
      }

      const formattedTaskId = `${projectPrefix}${nextTaskId
        .toString()
        .padStart(2, '0')}`;
      const finalTaskId = formattedTaskId.replace(/0(\d\d)$/, '$1');

      let assignedTo = '';
      let assignedDeveloperFirstName = '';
      let assignedDeveloperLastName = '';
      let assignedToRole = null;

      if (user.role === 'developer') {
        assignedTo = user.email;
        assignedDeveloperFirstName = user.first_name;
        assignedDeveloperLastName = user.last_name;
        assignedToRole = 'developer';
      } else if (user.role === 'lead') {
        if (taskData.assigned_to) {
          if (taskData.assigned_to === user.email) {
            // Lead assigned to themselves
            assignedTo = user.email;
            assignedDeveloperFirstName = user.first_name;
            assignedDeveloperLastName = user.last_name;
            assignedToRole = 'lead';
          } else {
            // Lead assigned to someone else
            const developerDoc = await getDoc(
              doc(db, 'user_details', taskData.assigned_to)
            );
            if (developerDoc.exists()) {
              const developerData = developerDoc.data();
              assignedTo = taskData.assigned_to;
              assignedDeveloperFirstName = developerData.first_name || '';
              assignedDeveloperLastName = developerData.last_name || '';
              assignedToRole = developerData.role || null;
            }
          }
        } else {
          // Lead didn't select an assignee, assign to themselves
          assignedTo = user.email;
          assignedDeveloperFirstName = user.first_name;
          assignedDeveloperLastName = user.last_name;
          assignedToRole = 'lead';
        }
      } else if (user.role === 'manager' && taskData.assigned_to) {
        // Manager is assigning the task to someone
        const developerDoc = await getDoc(
          doc(db, 'user_details', taskData.assigned_to)
        );
        if (developerDoc.exists()) {
          const developerData = developerDoc.data();
          assignedTo = taskData.assigned_to;
          assignedDeveloperFirstName = developerData.first_name || '';
          assignedDeveloperLastName = developerData.last_name || '';
          assignedToRole = developerData.role || null;
        }
      }
      let taskDoc;

      // if (taskData.status !== 'open') {

      taskDoc = {
        ...taskData,
        created_at: serverTimestamp(),
        creator: user.role,
        creator_email: user.email,
        owner_first_name: user.first_name,
        owner_last_name: user.last_name,
        id: finalTaskId,
        assigned_to: assignedTo,
        assigned_to_first_name: assignedDeveloperFirstName,
        assigned_to_last_name: assignedDeveloperLastName,
        assigned: Boolean(assignedTo) && assignedTo !== user.email,
        assigned_to_role: assignedToRole || null,
        estimation: `${taskData.estimation}`,
        start_time: taskData.start_time
          ? Timestamp.fromDate(new Date(taskData.start_time))
          : null,
        end_time: taskData.end_time
          ? Timestamp.fromDate(new Date(taskData.end_time))
          : null,
        attachments: attachments,
      };

      // }

      // Create task in global task list
      await setDoc(doc(db, 'task_list', finalTaskId), taskDoc);

      await updateDoc(projectDoc.ref, { task_id: nextTaskId });

      // Upload files after task creation
      if (selectedFiles.length > 0) {
        try {
          const result = await uploadTaskFiles(selectedFiles, finalTaskId);

          // Update task with file information
          const fileUrls = result.files.map((file) => ({
            fileName: file.fileName,
            url: file.url,
          }));
          await updateDoc(doc(db, 'task_list', finalTaskId), {
            attachments: fileUrls,
          });

          toast.success('Task created and files uploaded successfully!');
        } catch (error) {
          console.error('Error uploading files:', error);
          toast.error(
            'Task created but failed to upload files. Please try uploading files later.'
          );
        }
      } else {
        toast.success('Task created successfully!');
      }
      setAttachments([]);
      setUploadedFiles([]);
      setTaskData({
        description: '',
        status: taskStatuses[0],
        estimation: '',
        project_name: '',
        title: '',
        assigned_to: '',
        start_time: '',
        end_time: '',
        estimation_unit: 'h',
      });
      setSelectedFiles([]);
    } catch (error) {
      await logError('firebase_createTask', error.message, {
        collection: 'projects',
        projectName: taskData.project_name,
        hasFiles: selectedFiles.length > 0,
        component: 'CreateTask'
      });
      console.error('Error creating task:', error);
      toast.error('Failed to create task. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };
  const handleClearDate = () => {
    endDateTimeRef.current.blur();
  };
  const handleStartFocus = () => {
    setIsCalendarOpen({ start: true, end: false });
  };
  const handleStartBlur = () => {
    setIsCalendarOpen({ start: false, end: false });
  };
  const handleEndFocus = () => {
    setIsCalendarOpen({ start: false, end: true });
  };
  const handleEndBlur = () => {
    setIsCalendarOpen({ start: false, end: false });
  };
  const handleDateTimeChange = (e) => {
    const { name, value } = e.target;
    handleChange(e);

    // // Check if both date and time are selected
    // if (value.includes('T') && value.split('T')[1] !== '') {
    //   // Close the calendar pop-up
    //   if (name === 'start_time') {
    //     startDateTimeRef.current.blur();
    //   } else if (name === 'end_time') {
    //     endDateTimeRef.current.blur();
    //   }
    // }
  };

  // Add this function to get today's date in YYYY-MM-DD format
  const minDateTime = new Date().toISOString().slice(0, 16);

  return (
    <Layout>
      <ToastContainer position='top-right' autoClose={3000} />
      <div className='flex w-full h-[calc(100vh-4rem)]'>
        <SideMenu />
        <div className='flex-1 overflow-y-auto'>
          <div className='h-14 flex items-center justify-between px-6 border-b border-gray-200 bg-gradient-to-r from-orange-500 to-orange-600'>
            <h1 className='font-semibold text-xl text-white'>
              Create New Task
            </h1>
          </div>
          <div className='p-6 bg-gray-50'>
            <div className='bg-white p-6 rounded-lg shadow-md'>
              <div className='space-y-4'>
                <div className='grid grid-cols-2 gap-4'>
                  <div>
                    <label
                      className='block text-gray-700 text-sm font-bold mb-2'
                      htmlFor='project_name'
                    >
                      Project
                    </label>
                    <select
                      className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                      id='project_name'
                      name='project_name'
                      value={taskData.project_name}
                      onChange={handleChange}
                      required
                    >
                      <option value=''>Select a project</option>
                      {projects.map((project, index) => (
                        <option key={index} value={project}>
                          {capitalizeFirstLetter(project)}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label
                      className='block text-gray-700 text-sm font-bold mb-2'
                      htmlFor='status'
                    >
                      Status
                    </label>
                    <select
                      className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                      id='status'
                      name='status'
                      value={taskData.status}
                      onChange={handleChange}
                      required
                    >
                      {taskStatuses.map((status, index) => (
                        <option key={index} value={status}>
                          {status}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div>
                  <label
                    className='block text-gray-700 text-sm font-bold mb-2'
                    htmlFor='title'
                  >
                    Title
                  </label>
                  <input
                    className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                    id='title'
                    type='text'
                    name='title'
                    value={taskData.title}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div>
                  <label
                    className='block text-gray-700 text-sm font-bold mb-2'
                    htmlFor='description'
                  >
                    Description
                  </label>
                  <textarea
                    className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                    id='description'
                    name='description'
                    value={taskData.description}
                    onChange={handleChange}
                    required
                    rows='4'
                    style={{ minHeight: '100px' }}
                  />
                </div>
                <div className='grid grid-cols-2 gap-4'>
                  <div className='flex items-center space-x-2'>
                    <div className='flex-grow'>
                      <label
                        className='block text-gray-700 text-sm font-bold mb-2'
                        htmlFor='estimation'
                      >
                        Estimation
                      </label>
                      <input
                        className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                        id='estimation'
                        type='number'
                        name='estimation'
                        value={taskData.estimation}
                        onChange={handleChange}
                        required
                        min='0'
                        step='0.1'
                      />
                    </div>
                    <div className='w-24'>
                      <label
                        className='block text-gray-700 text-sm font-bold mb-2'
                        htmlFor='estimation_unit'
                      >
                        Time Frame
                      </label>
                      <select
                        className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                        id='estimation_unit'
                        name='estimation_unit'
                        value={taskData.estimation_unit}
                        onChange={handleChange}
                        required
                      >
                        <option value='h'>Hours</option>
                        <option value='d'>Days</option>
                        <option value='w'>Weeks</option>
                      </select>
                    </div>
                  </div>
                  {(user.role === 'lead' || user.role === 'manager') && (
                    <div>
                      <label
                        className='block text-gray-700 text-sm font-bold mb-2'
                        htmlFor='assigned_to'
                      >
                        Assign To
                      </label>
                      <select
                        className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                        id='assigned_to'
                        name='assigned_to'
                        value={taskData.assigned_to}
                        onChange={handleChange}
                        required
                      >
                        <option value=''>Select a user</option>
                        {developers.map((developer, index) => (
                          <option key={index} value={developer.email}>
                            {developer.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>

                <div className='grid grid-cols-2 gap-4'>
                  <div>
                    <label
                      className='block text-gray-700 text-sm font-bold mb-2'
                      htmlFor='start_time'
                    >
                      Start Time
                    </label>
                    <div className='relative'>
                      <input
                        className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline pr-10'
                        id='start_time'
                        type='datetime-local'
                        name='start_time'
                        value={taskData.start_time}
                        onChange={handleDateTimeChange}
                        ref={startDateTimeRef}
                        onFocus={handleStartFocus}
                        onBlur={handleStartBlur}
                        min={minDateTime}
                      />
                      {isCalendarOpen.start && (
                        <button
                          type='button'
                          className='absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700'
                          onClick={() => handleClearDate('start_time')}
                        >
                          <FaTimes />
                        </button>
                      )}
                    </div>
                  </div>
                  <div>
                    <label
                      className='block text-gray-700 text-sm font-bold mb-2'
                      htmlFor='end_time'
                    >
                      End Time
                    </label>
                    <input
                      className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100'
                      id='end_time'
                      type='datetime-local'
                      name='end_time'
                      value={taskData.end_time}
                      readOnly
                    />
                  </div>
                </div>
                <div>
                  <div className='mb-4'>
                    <label
                      className='block text-gray-700 text-sm font-bold mb-2'
                      htmlFor='attachments'
                    >
                      Attachments
                    </label>
                    <div className='flex items-center'>
                      <input
                        type='file'
                        id='attachments'
                        ref={fileInputRef}
                        multiple
                        onChange={handleFileSelection}
                        accept='.jpg,.jpeg,.png,.gif,.pdf,.doc,.docx,.xls,.xlsx,.txt'
                        className='hidden'
                      />
                      <button
                        onClick={handleUploadClick}
                        className='bg-white text-orange-500 border border-orange-500 px-4 py-2 rounded-lg hover:bg-orange-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 font-semibold transition duration-150 ease-in-out'
                      >
                        <FaUpload className='inline mr-2' /> Select Files
                      </button>
                      <span className='ml-3 text-sm text-gray-600'>
                        {selectedFiles.length} file(s) selected
                      </span>
                    </div>
                    <p className='text-sm text-gray-600 mt-2'>
                      Note: Maximum 10 files allowed. Each file must be 2MB or
                      less.
                    </p>
                  </div>

                  {selectedFiles.length > 0 && (
                    <div className='mt-4'>
                      <h3 className='text-gray-700 text-sm font-bold mb-2'>Selected Files:</h3>
                      <div className='grid grid-cols-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-1'>
                        {selectedFiles.map((file, index) => (
                          <div
                            key={index}
                            className='relative border rounded p-1 w-[150px] h-[130px] flex flex-col'
                          >
                            {file.preview ? (
                              <img
                                src={file.preview}
                                alt={file.file.name}
                                className='w-full h-[100px] object-cover'
                              />
                            ) : (
                              <div className='w-full h-[100px] flex items-center justify-center bg-gray-100'>
                                {getFileIcon(file.file.type)}
                              </div>
                            )}
                            <div className='mt-1 text-xs truncate flex-grow'>{file.file.name}</div>
                            <div className='absolute inset-0 flex items-center justify-center bg-black bg-opacity-20'>
                              <button
                                onClick={() => {
                                  setIsAttachDeleteModalOpen(true);
                                  setRemoveIndex(index);
                                }}
                                className='text-white hover:text-red-300 p-2 rounded-full transition-colors duration-200'
                              >
                                <FaTrash size={24} />
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>

                <div className='flex items-center justify-between'>
                  <button
                    className='bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
                    onClick={(e) => handleSubmit(e)}
                    disabled={isLoading}
                  >
                    {isLoading ? 'Creating...' : 'Create Task'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeleteConfirmationModal
        isOpen={isAttachDeleteModalOpen}
        onClose={() => setIsAttachDeleteModalOpen(false)}
        onConfirm={() => handleRemoveFile()}
        type={'project-delete'}
        message={`Are you sure you want to remove the attachment?`}
      />
    </Layout>
  );
};

export default CreateTask;
